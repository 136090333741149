<script setup lang="ts">
const open = ref(false)

const openSlideover = () => {
  open.value = true
}

const closeSlideover = () => {
  open.value = false
}

const title = 'Feedbackformulier'
</script>

<template>
  <BaseButton
    class="vertical-button !rounded-lg !shadow-md"
    data-cy="feedback-trigger"
    link-type="button"
    color="white"
    icon="message"
    size="sm"
    @click="openSlideover()"
  >
    Deel je feedback
  </BaseButton>

  <BaseSlideover :open="open" :title="title" size="sm" @close="closeSlideover">
    <FeedbackForm @close="closeSlideover">
      <div class="prose mb-4">
        <p>Websites kunnen er mooi uitzien en veel informatie geven. Maar wat heb je eraan als:</p>
        <ul>
          <li>taal te technisch is?</li>
          <li>je niet begrijpt wat er staat?</li>
          <li>een knop niet reageert?</li>
          <li>de site niet (goed) werkt?</li>
        </ul>
        <p>Heb je een idee hebt hoe het beter kan? Vertel het ons!</p>
      </div>
    </FeedbackForm>
  </BaseSlideover>
</template>

<style scoped>
.vertical-button {
  white-space: nowrap;
  bottom: 2%;
  right: 2%;
  position: fixed;
}
</style>
